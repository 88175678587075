import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconDirective } from '@seech/shared-ng';

@Component({
  selector: 'seech-color-picker',
  standalone: true,
  imports: [CommonModule, IconDirective, FormsModule],
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColorPickerComponent)
    }
  ]
})
export class ColorPickerComponent implements ControlValueAccessor {
  @ViewChild('colorInput') colorInputRef!: ElementRef;
  @Input() itemHeight!: string;
  @Input() itemWidth!: string;
  @Input() id!: string;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() limit = 1;
  @Input() label = 'Select Color';
  @Input() format: 'hex' | 'rgb' = 'hex'; // Configurable format
  @Output() colorChange = new EventEmitter<string[]>();
  @Output() errorEvent = new EventEmitter<string>();

  colors: string[] = [];
  color = '#000000'; // Default color in HEX
  editingColor?: number;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private onChange = (color: string[]) => { };

  constructor(private cdr: ChangeDetectorRef) {}


  // Update color based on the selected format
  setColor(event: Event) {
    if(this.colors.length >= this.limit && this.editingColor === undefined) {
      this.errorEvent.emit(`Cannot select more than ${this.limit} colors`);
      return;
    }

    const input = event.target as HTMLInputElement;
    if (this.format === 'hex') {
      this.color = input.value;
    }
    else if (this.format === 'rgb') {
      const rgb = this.hexToRgb(input.value);
      this.color = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    }

    if(this.colors.includes(this.color)){
      this.errorEvent.emit('Duplicate selection, Color already selected.');
      return;
    }

    if (this.editingColor || this.editingColor === 0) {
      this.colors[this.editingColor] = this.color;
      this.editingColor = undefined;
    }
    else {
      this.colors.push(this.color);
    }
    this.onChange(this.colors);
    this.colorChange.emit(this.colors);
  }

  // Convert HEX to RGB for RGB mode
  hexToRgb(hex: string): { r: number; g: number; b: number } {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 };
  }

  removeColor(index: number, event: Event) {
    event.stopPropagation();
    this.colors.splice(index, 1);
  }

  editColor(index: number) {
    if(this.readonly || this.disabled) return;
    this.color = this.colors[index];
    this.cdr.detectChanges();
    this.colorInputRef?.nativeElement.click();
    this.editingColor = index;
  }

  writeValue(colors?: string[]): void {
    if (colors) {
      this.colors = colors.slice(0, this.limit);
    }
  }

  registerOnChange(fn: (color: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}

