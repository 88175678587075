import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  forwardRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
  FormsModule,
} from '@angular/forms';
import { Currency } from '../currency/currency.component';
import {
  ConnectedPosition,
  OverlayModule,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import {
  IconDirective,
  SharedScrollEndNotifierDirective,
} from '@seech/shared-ng';
import { ValidationComponent } from '../validation/validation.component';

@Component({
  selector: 'seech-money',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    IconDirective,
    SharedScrollEndNotifierDirective,
    ValidationComponent,
  ],
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneyComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MoneyComponent),
      multi: true,
    },
  ],
})
export class MoneyComponent
  implements ControlValueAccessor, Validator, OnChanges
{
  @Input({ required: true }) currency!: string;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() textClass?: string;
  @Input() borderClass?: string;
  @Input() autocomplete: 'off' | 'on' = 'off';
  @Input() validateControl = true;
  @Input() placeholder = '';
  @Input() readonlyplaceholder = '';
  @Input() currencySearchPlaceholder = 'Search';
  @Input() fontSize = '0.8rem';
  @Input() label?: string;
  @Input() showCurrencySelector = false;
  @Input() currencies: Currency[] = [];
  @Input() threshold = 100;
  @Output() selected: EventEmitter<Currency> = new EventEmitter<Currency>();
  @Output() scrollEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() currencySearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueChange: EventEmitter<number | null> = new EventEmitter<
    number | null
  >();
  @Output() validationChange: EventEmitter<ValidationErrors | null> =
    new EventEmitter<ValidationErrors | null>();
  @Input() value?: string | null;
  @Input() showValidationMessage = true;

  scrollStrategy!: ScrollStrategy;
  isOpen = false;
  connectedPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 5,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -5,
    },
  ];

  showSearchResult = false;
  formattedValue?: string | null;
  currencySymbol?: string;
  countryCode?: string;

  showError?: boolean;
  errorMessage = '';

  private onChange = (value: any) => {};

  private onTouched = () => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.formattedValue = this.formatCurrency(this.value);
    this.onChange(this.formattedValue);

    if (changes['currencies'] || changes['currency']) {
      const selectedCurrency = this.currencies.find(
        (currency) => currency?.currencyCode === this.currency
      );
      this.currencySymbol = selectedCurrency?.currencySymbol;
      this.countryCode = selectedCurrency?.countryCode;
    }
  }

  onInputChange(e: any): void {
    this.value = e.target.value.replace(/[^0-9.]/g, ''); // Remove all non-numeric characters except decimal
    this.onChange(this.formatCurrency(this.value));
    this.valueChange.emit(this.value ? parseFloat(this.value) : null);
  }

  onSearch(e: any) {
    this.currencySearch.emit(e.target.value);
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  onSelected(currency: Currency) {
    this.selected.emit(currency);
    this.currency = currency.currencyCode;
    this.currencySymbol = currency?.currencySymbol;
    this.countryCode = currency?.countryCode;
  }

  onBlur(): void {
    this.formattedValue = this.formatCurrency(this.value);
    this.onChange(this.formattedValue);
    this.onTouched();
  }

  onFocus(): void {
    this.showError = undefined;
  }

  formatCurrency(value?: string | null): string | null | undefined {
    if (!value) return value;
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedValue = parts.join('.');
    return parseFloat(formattedValue) === 0 ? '' : formattedValue;
  }

  getValidationColorClass() {
    let borderColorClass = '';
    if (!this.disabled) {
      if (this.showError === false && this.validateControl) {
        borderColorClass = 'border-success';
      }
      if (this.showError === true && this.validateControl) {
        borderColorClass = 'border-danger';
      }
      if (this.readonly) {
        borderColorClass = 'border-primary-light';
      }
      if (this.disabled) {
        borderColorClass = 'border-disabled';
      }
    }
    return `${
      this.borderClass ? this.borderClass : borderColorClass
    } ${this.getTextColorClass()} ${this.getBackgroundColor()}`;
  }

  getBackgroundColor() {
    if (this.disabled) {
      return 'bg-disabled';
    }
    return 'mdb-bg';
  }

  getTextColorClass() {
    if (this.textClass) {
      return this.textClass;
    }

    if (this.readonly) {
      return 'text-primary-light';
    }

    if (this.disabled) {
      return 'color-disabled';
    }

    return 'mdb-text';
  }

  writeValue(value: any): void {
    this.value = value;
    this.formattedValue = this.formatCurrency(value);
    this.cdRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let error: ValidationErrors | null = null;
    if (
      (!control.value && this.required) ||
      (control.value && isNaN(parseFloat(control.value.replace(',', ''))))
    ) {
      this.showError = true;
      this.errorMessage = 'Amount is required';
      error = { required: true };
    } else if (control.value && parseFloat(control.value) < 0) {
      this.showError = true;
      this.errorMessage = 'Amount cannot be negative';
      error = { invalidAmount: true };
    } else {
      this.showError = false;
      this.errorMessage = '';
    }
    this.validationChange.emit(error);
    return error;
  }

  htmlSymbolToUnicode(currencySymbols: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = currencySymbols;
    return textArea.value;
  }
}
