import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { FileType as SharedFileType, SIZE } from '@seech/shared-ng';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ApplicationBlobType, MIME_TYPE_CATEGORIES } from '../../constants';

export type FileType = SharedFileType;

@Component({
  selector: 'seech-file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  exportAs: 'seechFileUpload',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})

export class FileUploadComponent
  implements ControlValueAccessor, OnChanges, Validator
{
  @Input() size: string = SIZE.MEDIUM;
  @Input() id: string | number | null | undefined;
  @Input() hidden = true;
  @Input() max = 1;
  @Input() capture: 'user' | 'environment' | null = 'environment';
  @Input() accept: (ApplicationBlobType | string)[] = []; // Allowed file types
  @Input() maxSizeInMb = 0;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Output() error: EventEmitter<string> = new EventEmitter<string>(); // Emits error messages

  classes = `form-control-${this.size}`;
  @Input() disabled = false;

  private value: FileList | null = null;
   
  ngOnChanges(changes: SimpleChanges): void {
    this.classes = `form-control-${this.size}`;
  }
   
  onChanged: (value: FileList | null) => void = () => {};
   
  onTouched: () => void = () => {};

  @HostListener('focusout')
  onFocusOut() {
    this.onTouched();
  }

  get multiple(): boolean {
    return this.max > 1;
  }

  /**
   * Handles the native file input change event
   */
  onFileUpload(e: any): void {
    const inputElement = e.target as HTMLInputElement;
    let files = inputElement.files;

    if (files) {
      const validationError = this.validateFiles(files);
      if (validationError) {
        this.error.emit(validationError);
        this.clearSelection();
        return;
      }

      if (files.length > this.max) {
        const limitedFiles = new DataTransfer();
        for (let i = 0; i < this.max; i++) {
          limitedFiles.items.add(files[i]);
        }
        files = limitedFiles.files;
      }

      this.value = files;
      this.selected.emit(e);
      this.onChanged(this.value);
      this.onTouched();
    }
  }

   /**
   * Dynamically resolves the "accept" attribute based on allowed formats
   */
   get acceptAttribute(): string {
    return this.resolveCombinedBlobTypes(this.accept).join(', ');
  }

  writeValue(value: FileList | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: FileList | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isParentDisabled: boolean): void {
    this.disabled = isParentDisabled;
  }

  triggerUpload() {
    this.fileInput.nativeElement.click();
  }

   /**
   * Clears the current file selection
   */
   clearSelection(): void {
    this.value = null;
    this.fileInput.nativeElement.value = '';
    this.selected.emit(this.value);
    this.onChanged(this.value);
  }

  
  /**
   * Validates uploaded files for size, count, and type
   */
  private validateFiles(files: FileList): string | null {
    let totalFileSize = 0;
    const allowedTypes = this.resolveCombinedBlobTypes(this.accept);

    if (files.length > this.max) {
      return `You can upload up to ${this.max} file(s).`;
    }

    for (const file of Array.from(files)) {
      totalFileSize += file.size / (1024 * 1024); // Convert to MB

      // Check for unsupported file types
      if (
        allowedTypes.length > 0 &&
        !allowedTypes.some((type) => type === file.type || (type.endsWith('/*') && file.type.startsWith(type.split('/')[0])))
      ) {
        return `Unsupported file type: ${file.type}`;
      }
    }

    if (this.maxSizeInMb > 0 && totalFileSize > this.maxSizeInMb) {
      return `Total file size should not exceed ${this.maxSizeInMb} MB.`;
    }

    return null;
  }

  /**
   * Validates the control's value
   */
  validate(control: AbstractControl): ValidationErrors | null {
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };

    if (!this.value && valueIsRequired) {
      this.error.emit('File selection is required.');
      return requiredError;
    }

    return null;
  }

  public resolveCombinedBlobTypes(allowedFormats: (ApplicationBlobType | string)[]): string[] {
    const resolvedTypes = new Set<string>();
  
    allowedFormats.forEach((format) => {
      if (format === 'media') {
        // Expand 'media' to 'image/*', 'video/*', 'audio/*'
        MIME_TYPE_CATEGORIES['media'].forEach((type) => resolvedTypes.add(type));
      } else if (MIME_TYPE_CATEGORIES[format as ApplicationBlobType]) {
        // Expand custom categories like 'document', 'archive', etc.
        MIME_TYPE_CATEGORIES[format as ApplicationBlobType].forEach((type) =>
          resolvedTypes.add(type)
        );
      } else {
        // Add wildcard types or specific MIME types directly
        resolvedTypes.add(format);
      }
    });
  
    return Array.from(resolvedTypes);
  }
}
