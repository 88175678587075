import { Environment } from "../app/models/environment";

export const environment: Environment = {
    production: true,
    GOOGLE_API_KEY: 'AIzaSyCR9vRtCUFO7Wbne2xG8-UMNxcLlxglNjE',
    DEMO_CDN_URL: 'https://dev-cdn.seech.com/demo/',
    storybook: {
        'host-ng': 'https://storybook.seech.com',
        'controls-ng': 'https://storybook.seech.com/controls-ng',
        'ux-ng': 'https://storybook.seech.com/ux-ng',
        'layout-ng': 'https://storybook.seech.com/layout-ng',
        'media-ng': 'https://storybook.seech.com/media-ng',
        'icon-ng': 'https://storybook.seech.com/icon-ng',
        'visualizations-ng': 'https://storybook.seech.com/visualizations-ng'
    }
  };
