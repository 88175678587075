import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[sch-character-limit]',
  standalone: true,
})
export class CharacterLimitDirective implements OnInit, AfterViewInit, OnChanges {
  private _limitCharacter = true; // Default value for limitCharacter

  @Input('sch-character-limit')
  set limitCharacter(value: boolean | string) {
    this._limitCharacter = value === '' || value === true || value === 'true';
  }
  get limitCharacter(): boolean {
    return this._limitCharacter;
  }

  @Input() maxLength: number | null = null;
  @Input() threshold = 80;
  @Input() embedCounter = true; // Controls whether the directive embeds the counter
  @Input() allowExceed = false;

  @Output() counterStateChange = new EventEmitter<{ text: string; class: string }>(); // Emits counter text and class in real-time
  @Output() characterLimitReached = new EventEmitter<void>();
  @Output() characterLimitExceeded = new EventEmitter<boolean>();

  private countElement!: HTMLElement;
  private isWithinLimit = true;
  private hasReachedLimit = false;
  private targetInput: HTMLInputElement | HTMLTextAreaElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    if (!this.limitCharacter) {
      return;
    }

    if (this.maxLength && this.maxLength > 0 && this.embedCounter) {
      this.createCountElement();
    }
  }

  ngAfterViewInit(): void {
    if (!this.limitCharacter) {
      return;
    }

    this.findTargetInput();
    if (this.targetInput) {
      this.renderer.listen(this.targetInput, 'input', (event: Event) => this.onInput(event));

      if (this.targetInput.value.length > 0) {
        this.onInput(new Event('input'));
      } else {
        this.updateCharacterCount();
      }
    }

    if (this.embedCounter && this.countElement) {
      this.attachCounterToInputOrLabel();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['maxLength'] && !changes['maxLength'].firstChange) {
      this.updateCharacterCount();
    }
  }

  private findTargetInput(): void {
    const hostElement = this.el.nativeElement;

    if (hostElement.tagName === 'INPUT' || hostElement.tagName === 'TEXTAREA') {
      this.targetInput = hostElement;
    } else {
      this.targetInput = hostElement.querySelector('input, textarea');
    }

    if (!this.targetInput) {
      console.error('CharacterLimitDirective: No input or textarea element found.');
    }
  }

  private attachCounterToInputOrLabel(): void {
    const id = this.targetInput?.id;

    if (id) {
      const label = document.querySelector(`label[for="${id}"], sch-input-label[for="${id}"]`);
      if (label) {
        this.attachCounterToLabel(label as HTMLElement);
        return;
      }
    }

    this.embedCounterDirectlyBelowInput();
  }

  private attachCounterToLabel(label: HTMLElement): void {
    if (!this.countElement) {
      console.warn('countElement is not initialized.');
      return; // Prevent attaching an undefined element
    }

    const container = this.createFlexContainer();
    this.renderer.appendChild(container, label);
    this.renderer.appendChild(container, this.countElement);

    const parent = this.el.nativeElement.parentNode;
    this.renderer.insertBefore(parent, container, this.el.nativeElement);
  }

  private embedCounterDirectlyBelowInput(): void {
    const container = this.createFlexContainer();
    this.renderer.appendChild(container, this.countElement);
    this.renderer.insertBefore(this.targetInput!.parentNode, container, this.targetInput!.nextSibling);
  }

  private createFlexContainer(): HTMLElement {
    const flexContainer = this.renderer.createElement('div');
    this.renderer.addClass(flexContainer, 'character-limit-counter');
    this.renderer.setStyle(flexContainer, 'display', 'flex');
    this.renderer.setStyle(flexContainer, 'justify-content', 'space-between');
    this.renderer.setStyle(flexContainer, 'margin-top', '0.1rem');
    return flexContainer;
  }

  onInput(event: Event): void {
    if (!this.maxLength || this.maxLength <= 0 || !this.targetInput) {
      return;
    }

    let currentLength = this.targetInput.value.length;

    if (!this.allowExceed && currentLength > this.maxLength) {
      this.targetInput.value = this.targetInput.value.substring(0, this.maxLength);
      currentLength = this.maxLength;

      const inputEvent = new Event('input', { bubbles: true });
      this.targetInput.dispatchEvent(inputEvent);
    }

    if (currentLength === this.maxLength && !this.hasReachedLimit) {
      this.hasReachedLimit = true;
      this.characterLimitReached.emit();
    }

    if (this.allowExceed && currentLength > this.maxLength) {
      this.emitExceedState(false);
    } else {
      this.emitExceedState(true);
      this.hasReachedLimit = false;
    }

    this.updateCharacterCount(currentLength);
  }

  private emitExceedState(isWithinLimit: boolean): void {
    if (this.isWithinLimit !== isWithinLimit) {
      this.isWithinLimit = isWithinLimit;
      this.characterLimitExceeded.emit(!isWithinLimit);
    }
  }

  private createCountElement(): void {
    this.countElement = this.renderer.createElement('div');
    this.renderer.addClass(this.countElement, 'hint');
    this.renderer.setStyle(this.countElement, 'font-size', '12px');
    this.renderer.setAttribute(this.countElement, 'aria-live', 'polite'); // Accessibility
  }

  private updateCharacterCount(currentLength = 0): void {
    if (!this.targetInput || !this.maxLength) {
      return;
    }

    let text = '';
    let className = '';

    if (currentLength <= this.maxLength) {
      text = `${currentLength}/${this.maxLength} Characters.`;
      className = currentLength >= this.maxLength * (this.threshold / 100) ? 'text-warning' : 'normal';
    } else {
      const overCount = currentLength - this.maxLength;
      text = `Over by ${overCount} characters.`;
      className = 'text-danger';
    }

    // Update embedded counter if enabled
    if (this.embedCounter && this.countElement) {
      this.countElement.textContent = text;
      this.applyClass(className);
    }

    // Emit counter text and class
    this.counterStateChange.emit({ text, class: className });
  }

  private applyClass(className: string): void {
    const classes = ['normal', 'text-warning', 'text-danger'];
    classes.forEach((cls) => this.renderer.removeClass(this.countElement, cls));
    this.renderer.addClass(this.countElement, className);
  }
}
