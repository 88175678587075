import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  CUSTOM_ELEMENTS_SCHEMA,
  OnChanges,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { TextboxVariant } from '@seech/shared-ng';
import { TEXTBOX_VARIANT } from '../../constants';
import { InputDirective } from '../../directives';
import { ControlsConfigService } from '../../injection/controls-config.service';

export type ExpDateFormat = 'mm/yyyy' | 'yyyy/mm' | 'mm/yy' | 'yy/mm';

@Component({
  selector: 'seech-expiration-date',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputDirective],
  templateUrl: './expiration-date.component.html',
  styleUrl: './expiration-date.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpirationDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ExpirationDateComponent),
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpirationDateComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator
{
  @Input() value: any = '';
  @Input() label?: string;
  @Input() placeholder = 'Select Date';
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() id = 'exp-date';
  @Input() format: ExpDateFormat = 'mm/yy';
  @Input() variant!: TextboxVariant;
  @Input() autocomplete: 'on' | 'off' = 'off';

  @Output() valid: EventEmitter<boolean> | any = new EventEmitter<boolean>();
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();

  isFormatComplete = false;
  textboxVariants = TEXTBOX_VARIANT;

  maxLength = 5; // Default for 'mm/yy'

  private onChange = (value: any) => {};
  private onTouched = () => {};

  constructor(
    private cdr: ChangeDetectorRef,
    private configService: ControlsConfigService
  ) {}

  ngOnInit(): void {
    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }

    this.updateMaxLength();
  }

  ngOnChanges(): void {
    this.updateMaxLength();
  }

  updateMaxLength(): void {
    // Determine max length based on format
    this.maxLength = this.format.includes('yyyy') ? 7 : 5;
  }

  onInput(event: any): void {
    let input = event.target.value;

    // Get the key pressed (for detecting backspace)
    const key = event.inputType || event.key;
    const slashPosition = this.format.startsWith('yyyy') ? 4 : 2;

    // Remove non-numeric and slashes
    input = input.replace(/[^0-9/]/g, '');

    // Automatically insert `/` at the correct position if not backspacing
    if (
      input.length === slashPosition &&
      key !== 'deleteContentBackward' &&
      key !== 'Backspace'
    ) {
      if (!input.includes('/')) {
        input += '/';
      }
    }

    // Limit input length based on format
    if (input.length > this.maxLength) {
      input = input.slice(0, this.maxLength);
    }

    // Update the value
    event.target.value = input;
    this.value = input;
    this.onChange(this.value);
    this.onTouched();
    this.valueChange.emit(this.value);

    // Check if the format is complete and validate
    this.isFormatComplete =
      this.value.length === this.maxLength && this.isValidDate();
    this.valid.emit(this.isFormatComplete);
  }

  isValidDate(): boolean {
    const parts = this.value.split('/');

    if (parts.length !== 2) {
      return false; // Invalid format
    }

    let month, year;

    // Dynamically assign year and month based on the format
    if (this.format === 'yyyy/mm') {
      year = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10);
    } else if (this.format === 'mm/yyyy') {
      month = parseInt(parts[0], 10);
      year = parseInt(parts[1], 10);
    } else if (this.format === 'yy/mm') {
      year = parseInt(parts[0], 10);
      month = parseInt(parts[1], 10);
    } else if (this.format === 'mm/yy') {
      month = parseInt(parts[0], 10);
      year = parseInt(parts[1], 10);
    } else {
      return false; // Unsupported format
    }

    // Validate month (01-12)
    if (!month || month < 1 || month > 12) {
      return false;
    }

    // Validate year
    const currentYear = new Date().getFullYear();
    const shortYear = currentYear % 100;

    if (this.format.includes('yyyy')) {
      if (!year || year < currentYear) {
        return false;
      }
    } else if (this.format.includes('yy')) {
      if (!year || year < shortYear) {
        return false;
      }
    }

    return true;
  }

  writeValue(value: string): void {
    if (value) {
      this.value = value;
    }
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };

    // Check if the value is required but not provided
    if (!this.value && valueIsRequired) {
      return requiredError;
    }

    // Check if the date is invalid when a value is present
    if (this.value && !this.isValidDate()) {
      return { invalidDate: true };
    }

    return null;
  }

  convertToUpperCase(text) {
    return text.toUpperCase();
  }
}
