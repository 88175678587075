<div class="color-picker-wrapper" [ngStyle]="{'height': itemHeight}"  [attr.readonly]="readonly">
    <div class="color-picker-wrapper-inner">
        <div class="add-button color" 
            *ngIf="(!readonly && !disabled)"
            [ngStyle]="{'flex-basis': itemWidth}"
            (click)="colorInput.click()">
                <i [sch-icon]="'add-circle'"></i>
        </div>

        <div class="color" 
            *ngFor="let color of colors; let i = index"
            [ngStyle]="{'flex-basis': itemWidth, 'background-color': color}"
            (click)="editColor(i)">

            <div class="action-wrapper" *ngIf="(!readonly && !disabled)" (click)="removeColor(i, $event)">
                <i [sch-icon]="'close'"></i>
            </div>
        </div>

        <input 
            type="color" 
            [value]="color" 
            class="color-picker"
            (change)="setColor($event)" #colorInput/>
    </div>
</div>

  