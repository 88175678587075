import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CONTROL_TYPE, TEXTBOX_VARIANT } from '../../constants';
import { InputDirective } from '../../directives';
import { Icon, IconDirective } from '@seech/shared-ng';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ValidationComponent } from '../validation/validation.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedTooltipDirective, TextboxVariant } from '@seech/shared-ng';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { Alignment } from '../../models';
@Component({
  selector: 'seech-input',
  standalone: true,
  imports: [
    CommonModule,
    InputDirective,
    ReactiveFormsModule,
    FormsModule,
    ValidationComponent,
    MdbFormsModule,
    SharedTooltipDirective,
    IconDirective,
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() type = CONTROL_TYPE.TEXT;
  @Input() label?: string;
  @Input() id = '';
  @Input() value: any = '';
  @Input() autocomplete?: 'on' | 'off' = 'off';
  @Input() placeholder = '';
  @Input() readonlyplaceholder = '';
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() alignIcon: Alignment = 'right';
  @Input() icon?: Icon;
  @Input() variant!: TextboxVariant;
  @Input() textClass?: string;
  @Input() borderClass?: string;
  @Input() maxLength = 0;  // Default to no min length restriction

  @Output() valueChange: any = new EventEmitter<any>();
  @Output() valid: any = new EventEmitter<boolean>();
  @Output() actionIconClick: any = new EventEmitter<void>();
  @Output() blurred = new EventEmitter<void>();

  validInput = true;
  passwordVisible = false;
  controlType = CONTROL_TYPE;
  textboxVariants = TEXTBOX_VARIANT;

  get hasIcon(): boolean {
    if (this.disabled || this.readonly) return false;
    return !!this.icon;
  }

  constructor(private configService: ControlsConfigService) { }

  ngOnInit(): void {
    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }
  }

  handleValidation(event: any): void {
    this.validInput = event;
    this.valid.emit(this.validInput);
  }

  onInput(event): void {
    this.value = event.target.value;
    this.onChange(this.value); // this will notify the parent form of the value change
    this.valueChange.emit(this.value);
  }

   
  private onChange = (value: any) => {
    // console.log("onChange", value);
  };
  private onTouched = () => {
    // console.log("onTouched");
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onBlur() {
    this.onTouched(); // this will notify the parent form that the input field has been touched
    this.blurred.emit();
  }
}
