import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata,
} from '@angular/animations';

export const seechLayoutAnimations: {
  readonly transformLayout: AnimationTriggerMetadata;
} = {
  // Animation that slides a layout in and out.
  transformLayout: trigger('transform', [
    state(
      'open, open-instant',
      style({
        transform: 'translateX(0%)',
      })
    ),
    state(
      'void',
      style({
        transform: 'translateX(-100%)',
      })
    ),
    transition('void => open, void => open-instant', animate('0.4s linear')),
    transition('void <=> open, open-instant => void', animate('0.4s linear')),
  ]),
};
