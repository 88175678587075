<label *ngIf="label" class="money-input-label" [class]="getTextColorClass()">{{ label }}</label>
<div class="d-flex W-100">
  <ng-container *ngIf="showCurrencySelector">
    <button
      cdkOverlayOrigin
      #searchResultOrigin="cdkOverlayOrigin"
      [class]="getValidationColorClass()"
      [disabled]="disabled"
      [style.font-size]="fontSize"
      (click)="(!disabled && !readonly) ? showSearchResult = true : null"
      type="button"
      class="money-dropdown d-flex justify-content-center align-items-center gap-1"
    >
      <img
        width="19"
        src="https://flagcdn.com/{{ countryCode | lowercase }}.svg"
      />
      {{ currency }}
      <span
        *ngIf="!disabled && !readonly"
        [sch-icon]="showSearchResult ? 'arrow-drop-up' : 'arrow-drop-down'"
      ></span>
    </button>
    <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop (detach)="showSearchResult = false"
      cdkConnectedOverlayBackdropClass="bg-transparent" [cdkConnectedOverlayPositions]="connectedPositions"
      (backdropClick)="showSearchResult = false" [cdkConnectedOverlayOpen]="showSearchResult"
      [cdkConnectedOverlayOrigin]="searchResultOrigin">
      <div class="search-result mdb-bg-body">
        <div class="search-input">
          <input [style.font-size]="fontSize" (keyup)="onSearch($event)" type="text"
            class="w-100 border-0 currency-search mdb-bg-body" [placeholder]="currencySearchPlaceholder" />
          <i [sch-icon]="'lens'"></i>
        </div>
        <hr class="m-0" />
        <div class="overlay-content" sch-scroll-end-notifier (scrollEnd)="onScrollEnd()" [offset]="threshold">
          <button *ngFor="let currency of currencies" (click)="onSelected(currency); showSearchResult = false"
            [style.font-size]="fontSize"
            class="list-item d-flex gap-2 mdb-bg-body align-items-center list-group-item-action list-group-item">
            <img width="25" src="https://flagcdn.com/{{
                currency.countryCode | lowercase
              }}.svg" />
            <span>{{ currency.currencyName }} (<span [innerHTML]="currency.currencySymbol"></span>
              {{ currency.currencyCode }})</span>
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>

  @let unicodeLength = htmlSymbolToUnicode(currencySymbol!).length;
  <fieldset class="flex-grow-1" [attr.data-before]="htmlSymbolToUnicode(currencySymbol!)" [disabled]="disabled"
    [class.money-container]="currencySymbol" [class]="getTextColorClass()">
    <input [style.font-size]="fontSize" type="text" [(ngModel)]="formattedValue" (keyup)="onInputChange($event)"
      [class.input-with-symbol]="currencySymbol"
      [ngClass]="{'input-with-symbol': unicodeLength===1, 'input-with-symbol2': unicodeLength===2 , 'input-with-symbol3': unicodeLength===3 , }"
      (blur)="onBlur()" (focus)="onFocus()" class="money-input-control"
      [class.input-with-selector]="showCurrencySelector" [autocomplete]="autocomplete"
      [placeholder]="readonly ? readonlyplaceholder : placeholder" [disabled]="disabled" [readonly]="readonly"
      [class]="getValidationColorClass()" />
  </fieldset>
</div>

<seech-validation *ngIf="validateControl && showValidationMessage && showError" [message]="errorMessage"
  [isError]="showError"></seech-validation>
