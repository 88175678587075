import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbTimepickerComponent, MdbTimepickerModule } from 'mdb-angular-ui-kit/timepicker';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ValidationService } from '../../services/validation.service';
import { InputDirective } from '../../directives';
import { IconDirective, TextboxVariant } from '@seech/shared-ng';
import { ControlsConfigService } from '../../injection/controls-config.service';
import { TEXTBOX_VARIANT } from '../../constants';
import { Alignment } from '../../models';

export interface TimeValue{
  h: string;
  m: string;
  ampm?: 'AM' | 'PM'
}

@Component({
  selector: 'seech-time',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MdbTimepickerModule,
    ReactiveFormsModule,
    MdbFormsModule,
    IconDirective,
    InputDirective
  ],
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeComponent),
      multi: true,
    },
  ],
})
export class TimeComponent implements ControlValueAccessor, Validator, OnChanges, OnInit {
  @ViewChild('timepicker', { static: true }) timepicker!: MdbTimepickerComponent;
  @Input() value: any = { h: 12, m: 0, s: 0, ampm: 'AM' };
  @Input() label?: string;
  @Input() id = 'time';
  @Input() placeholder = 'Select a time';
  @Input() readonlyplaceholder = '';
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() useInline = false;
  @Input() format24 = false;
  @Input() maxTime: any = null;
  @Input() minTime: any = null;
  @Input() required = false;
  @Input() variant!: TextboxVariant;
  @Input() autocomplete: 'on' | 'off' = 'off';
  @Input() alignIcon: Alignment = 'right';

  @Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() timeChange: EventEmitter<string> = new EventEmitter<string>();
  // This allows two-way binding
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() openTime: EventEmitter<any> = new EventEmitter<any>()
  @Output() valid: EventEmitter<boolean> | any = new EventEmitter<boolean>();


   
  private onChange = (value: any) => { };
   
  private onTouched = () => { };
  showTimeIcon = true;
  constructor(
    private cdr: ChangeDetectorRef,
    private validationService: ValidationService,
    private configService: ControlsConfigService
  ) { 
  }

  isValidTime = true;
  isUserTyping = false;
  textboxVariants = TEXTBOX_VARIANT;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['disabled'] || changes['readonly']) {
      if(changes['disabled']?.currentValue || changes['readonly']?.currentValue){
        this.showTimeIcon = false;
      }
      else {
        this.showTimeIcon = true;
      }
    }
  }

  ngOnInit(): void {
    if (!this.variant) {
      const config = this.configService.getConfig();
      this.variant = config?.textboxVariant ? config.textboxVariant : 'classic';
    }
  }

  formatTime(event: any): void {
    this.isUserTyping = true;
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    // Define the regex based on the time format (24-hour or 12-hour)
    const regex = this.format24 ? /[^0-9:]/g : /[^0-9:APMapm\s]/g; // Allow space character for 12-hour format
    // if format24 should only accept 12:10 and nothing more else if not format24 should only accept 10:10 AM/PM and nothing more
    if (this.format24) {
      if (inputValue.length > 4) {

        inputElement.value = inputValue.slice(0, 5);
        // test regex

      }
      if (regex.test(inputElement.value)) {
        inputElement.value = inputElement.value.replace(regex, '');
      }
    }

    if (!this.format24) {
      if (inputValue.length === 5 || inputValue.length === 6) {

        inputElement.value = inputElement.value.replace(/\s*(AM|PM)/gi, ' $1');

        if (!/\s(AM|PM)$/.test(inputElement.value)) {
          inputElement.value = inputElement.value.replace(/(AM|PM)$/gi, ' $1');
        }
      }
      if (inputValue.length > 8) {
        inputElement.value = inputValue.slice(0, 8);

      }
      if (regex.test(inputElement.value)) {
        inputElement.value = inputElement.value.replace(regex, '');
      }



    }


    // Function to validate time and emit validity
     
    const validateTime = (value: string) => {
      const isValid = this.getTimeValidity(); // Ensure this method checks the validity correctly
      this.isValidTime = isValid;
      // console.log('isValid', isValid)
      this.valid.emit(isValid);
    };

    // Function to update the input and emit changes
    const updateAndEmit = (value: string) => {
      inputElement.value = value;
      this.value = value;
      this.timeChange.emit(value);
      this.valueChange.emit(value); // For two-way binding.
      this.onChange(value);
      this.onTouched();
      validateTime(value); // Validate after each change
    };

    // Check if backspace or delete was pressed and remove the colon or AM/PM
    if (event.inputType === 'deleteContentBackward') {
      if (inputElement.value.endsWith(':')) {
        inputElement.value = inputElement.value.slice(0, -1);
      } else if (/\s(AM|PM)$/i.test(inputElement.value)) {
        inputElement.value = inputElement.value.slice(0, -3); // Remove the space along with AM or PM
      }
    }

    // Add colons or space for AM/PM as needed, but not if deleteContentBackward was pressed
    if (event.inputType !== 'deleteContentBackward') {

      if (inputElement.value.length === 2 && inputElement.value.indexOf(':') === -1) {
        inputElement.value += ':';
      } else if (!this.format24 && inputElement.value.length === 5 && inputElement.value.indexOf(' ') === -1) {
        inputElement.value += ' '; // Add a space after the time for 12-hour format
      }
    }


    // Update the input and emit changes
    updateAndEmit(inputElement.value);


    // Trigger change detection\
    this.getTimeValidity()
    this.isUserTyping = false;
    this.cdr.detectChanges();
  }

  private getTimeValidity(): boolean {
    // Ensure the value is in the correct string format
    return this.validationService.validateTime(this.value, this.format24 ? '24' : '12');
  }

  openTimePicker() {
    this.timepicker.open();
    this.openTime.emit()
  }
  onOpened() {
    this.getTimeValidity()
    this.opened.emit(true);
    this.closed.emit(false);
  }


  onClosed() {
    this.closed.emit(true);
    this.opened.emit(false);
  }

  onTimeChanged(event: any, initial = false) {
    // Format the time based on the format24 flag
    const formattedTime = this.format24
      ? `${event.h}:${event.m}`
      : `${event.h}:${event.m} ${event.ampm}`;

    this.value = formattedTime;
    if(!initial) {
      this.onChange(formattedTime);
      this.onTouched();
      this.timeChange.emit(formattedTime);
      this.valueChange.emit(formattedTime); // For two-way binding.
      this.valid.emit(this.getTimeValidity());
    }
  }

  writeValue(value: TimeValue): void {
    if (value) {
      value.ampm = +value.h < 12 ? 'AM' : 'PM';
      value.h = (this.format24 || +value.h < 12) ? value.h : (+value.h - 12).toString();
      this.onTimeChanged(value, true);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }


  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled || this.disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const valueIsRequired = control.hasValidator(Validators.required);
    const requiredError = { required: true };
    if (!value && valueIsRequired) {
      return requiredError;
    }

    const isValid = value ? this.getTimeValidity() : true;
    return isValid ? null : { invalidTime: true };
  }
}


