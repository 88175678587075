<label [hidden]="hidden" class="form-label" [attr.for]="id">
  <ng-content></ng-content>
</label>
<input 
  type="file" 
  #fileInput 
  (change)="onFileUpload($event)" 
  [multiple]="multiple" 
  class="form-control" 
  [class]="classes" 
  [id]="id" 
  [disabled]="disabled" 
  [hidden]="hidden" 
  [accept]="acceptAttribute" 
  
  />
