import { FILL as SHARED_FILL } from '@seech/shared-ng';

export const FILL = SHARED_FILL;

export const CONTROL_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  FILE: 'file',
  SELECT: 'select',
  SWITCH: 'switch',
  PASSWORD: 'password',
  BUTTON: 'button',
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'date-time',
  URL: 'url',
  EMAIL: 'email',
  PHONE: 'phone',
  AUTOCOMPLETE: 'auto-complete',
  COMBO_BUTTON: 'combo-button',
  COMBO_SELECT: 'combo-select',
  ZIPCODE: 'zip-code',
  SSN: 'us-ssn',
};

export const TEXTBOX_VARIANT = {
  CLASSIC: 'classic',
  MODERN: 'modern',
  HYBRID: 'hybrid',
};

export type ApplicationBlobType =
  | 'document'
  | 'archive'
  | 'web'
  | 'media'
  | 'image/*'
  | 'video/*'
  | 'audio/*'
  | 'app'
  | 'unknown';

export const MIME_TYPE_CATEGORIES: Record<ApplicationBlobType, string[]> = {
  document: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  archive: [
    'application/zip',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
  ],
  web: ['application/json', 'application/xml', 'text/html'],
  media: ['image/*', 'video/*', 'audio/*'],
  'image/*': ['image/*'],
  'video/*': ['video/*'],
  'audio/*': ['audio/*'],
  app: [
    'application/vnd.android.package-archive', // APK files
    'application/x-msdownload', // EXE files
    'application/x-sh', // Shell scripts
    'application/x-dmg', // DMG files
    'application/x-app-installer', // App installers
  ],
  unknown: [],
};
