<div class="sch-input">
    <label *ngIf="label && variant === textboxVariants.CLASSIC" class="sch-input-label {{variant}}" for="{{ id }}"
        [attr.readonly]="readonly" [attr.disabled]="disabled">
        {{ label }}
    </label>

    <div class="position-relative">
        <input placeholder="{{ placeholder }}" type="text" id="{{ id }}" [disabled]="disabled" [value]="value"
            [placeholder]="convertToUpperCase(format)" [class.has-value]="value" [maxlength]="maxLength" sch-input
            class="sch-input-field has-placeholder {{variant}}" autocomplete="{{autocomplete}}" [readonly]="readonly"
            [disabled]="disabled" (input)="onInput($event)" />

        <label *ngIf="label && variant !== textboxVariants.CLASSIC" class="sch-input-label {{variant}}" for="{{ id }}"
            [attr.readonly]="readonly" [attr.disabled]="disabled">
            {{ label }}
        </label>
    </div>
</div>