import { Component, HostBinding, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'seech-placeholder',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
  animations: [
    trigger('pulse', [
      transition(':enter', [
        animate('1.5s ease-in-out', style({ opacity: 0.5 })),
        animate('1.5s ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('1.5s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ],
})
export class PlaceholderComponent {
  @Input() width = '100%';
  @Input() height = '1em';
  @Input() shape: 'rect' | 'circle' = 'rect';
  @Input() color= '#E0E0E0';
  @Input() borderRadius= '1px';

  @HostBinding('style.height') get _height() {
    return this.height;
  }

  @HostBinding('style.width') get _width() {
    return this.width;
  }

  getStyles() {
    return {
      'width': this.width,
      'height': this.shape === 'circle' ? this.width : this.height,
      'background-color': this.color,
      'border-radius': this.shape === 'circle' ? '50%' : this.borderRadius,
    };
  }
}
