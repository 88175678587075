<div class="sch-input">
  <label
    *ngIf="label && variant === textboxVariants.CLASSIC"
    class="sch-input-label {{ variant }}"
    for="{{ id }}"
    [attr.readonly]="readonly"
    [attr.disabled]="disabled"
  >
    {{ label }}
  </label>

  <div class="position-relative">
    <div
      [ngClass]="{
        'sch-text-area adaptive-textarea': !label
      }"
    >
      <textarea
        cdkTextareaAutosize
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="minRows"
        [cdkAutosizeMaxRows]="maxRows"
        [id]="id"
        (isValid)="handleValidation($event)"
        [value]="value"
        (input)="onInput($event)"
        (blur)="inputBlurred()"
        [autocomplete]="autocomplete"
        class="sch-text-area  {{ variant }}"
        [ngClass]="{
          'sch-text-area': label
        }"
        [class.has-value]="value"
        [class.has-placeholder]="placeholder"
        [placeholder]="readonly ? readonlyplaceholder : placeholder"
        [readonly]="readonly"
        [disabled]="disabled"
        [maxLength]="maxLength"
        [threshold]="threshold"
        [embedCounter]="embedCounter"
        [allowExceed]="allowExceed"
        [sch-character-limit]="limitCharacters"
        (characterLimitReached)="onCharacterLimitReached()"
        (characterLimitExceeded)="onCharacterLimitExceeded($event)"
        (counterStateChange)="counterTextChange($event)"
      >
      </textarea>
      <div
        *ngIf="!label"
        class="hint {{ counterContents.class }} itallics text-end"
        aria-live="polite"
      >
        {{ counterContents.text }}
      </div>
    </div>

    <label
      *ngIf="label && variant !== textboxVariants.CLASSIC"
      class="sch-input-label {{ variant }}"
      for="{{ id }}"
      [attr.readonly]="readonly"
      [attr.disabled]="disabled"
    >
      {{ label }}
    </label>
  </div>
</div>
