<div mdbDropdown class="dropdown">  
  <div mdbDropdownToggle>
    <div #customToggle>
      <ng-content select="[toggle]"></ng-content>
    </div>
  
    <ng-container *ngIf="!customToggle.children.length"> 
      <button [class.disabled]="disabled" [variant]="variant" type="button" [id]="id" sch-button [size]="size" [color]="color"
        [fontColor]="fontColor" [shape]="shape" [hideBorder]="hideBorder">
          <i *ngIf="icon" [sch-icon]="icon"></i>
          <span *ngIf="label" class="label">{{ label }}</span>
          <i *ngIf="showDropDownIcon" [sch-icon]="'collapse'"></i>
      </button>
    </ng-container>
  </div>

  
  <ul 
    [id]="computeId('dropdown-menu')" 
    mdbDropdownMenu class="dropdown-menu" 
    [class]="menuAlignmentClasses"
    [style.minWidth]="minWidth"
    [attr.aria-labelledby]="id">
    <ng-content> </ng-content>
  </ul>
</div>